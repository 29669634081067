export const options = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
  shouldRetryOnError: false,
};

export type DashboardHeader = {
  id: string;
  headerName: string;
};

export const dashboardTabs: DashboardHeader[] = [
  {
    id: "companies",
    headerName: "Companies",
  },
  {
    id: "updates",
    headerName: "Updates",
  },
  {
    id: "news",
    headerName: "News",
  },
  {
    id: "network_collaboration",
    headerName: "Network Collaboration",
  },
];
