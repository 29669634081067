import Link from "next/link";
import { OpportunityProps } from "../../api/types";
import Avatar from "../Avatar";

export const OpportunityLinkedDeal = ({ opportunity }: OpportunityProps) => {
  return (
    <Link
      href={`/opportunity/${opportunity?.id}`}
      key={opportunity?.id}
      className="flex min-h-[2rem] flex-row items-center justify-between gap-x-2 rounded-xl border border-gray-200 bg-white pr-2 text-xs shadow-md md:h-8 md:min-w-max"
    >
      <div
        className={`h-full w-1.5 rounded-l-2xl ${
          opportunity?.status === "active"
            ? "bg-green-500"
            : opportunity?.status === "closed"
              ? "bg-red-500"
              : opportunity?.status === "revisit"
                ? "bg-yellow-500"
                : ""
        }`}
      />
      <div className="w-full font-bold text-blue-900">{opportunity?.name}</div>
      <Avatar
        name={opportunity?.owner?.name}
        size={20}
        url={opportunity?.owner?.image_url}
      />
    </Link>
  );
};
