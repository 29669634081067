import { Popover, Transition } from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Field, FieldProps, useFormikContext } from "formik";
import React, { Fragment } from "react";
import { AdditionalInvestor, OrganizationFormValues } from "../../../api/types";
import AutocompleteMultiselect from "../../Select/AutocompleteMultiselect";
import FormVMFSelectedField from "../../Select/FormVMFSelectedField";
import PersonSelect from "../../Select/PersonSelect";
import Select from "../../Select/Select";
import { classNames } from "../../utils";

type InvestorPartProps = {
  index: number;
  remove: any;
  currentSize: number;
  setIsBDPart: React.Dispatch<React.SetStateAction<boolean>>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  deletedInvestorIds: number[];
  setDeletedInvestorIds: React.Dispatch<React.SetStateAction<number[]>>;
  investor: AdditionalInvestor;
};

const holdingsOptions = [
  { value: "active", label: "Active" },
  { value: "exit", label: "Exit" },
];

const FinancingStagesTooltip: any = [
  {
    report: "Preferred Seed",
    stage: "Seed",
  },
  {
    report: "Series A",
    stage: "Series A",
  },
  {
    report: "Series A1",
    stage: "Series A",
  },
  {
    report: "Preferred A",
    stage: "Series A",
  },
  {
    report: "Series A – Tranche 1",
    stage: "Series A",
  },
  {
    report: "SAFE – converted to preferred A2",
    stage: "Series A",
  },
];

const HoldingStatusTooltip: any = [
  {
    report:
      "Network fund still has shareholdings in VPC, even after it's been acquired or listed.",
    stage: "Active",
  },
  {
    report:
      "LP Report should mention a complete exit. Partial exit, or fair value write-off/markdown, are NOT considered complete exits.",
    stage: "Exit",
  },
  {
    report: "Listed on stock exchange and still holding shares",
    stage: "Active",
  },
];

const OrganizationVPCForm = ({
  index,
  remove,
  currentSize,
  setIsBDPart,
  setFieldValue,
  deletedInvestorIds,
  setDeletedInvestorIds,
  investor,
}: InvestorPartProps) => {
  const { values, errors, touched } =
    useFormikContext<OrganizationFormValues>();
  const errorInvestorPart = errors.additional_investors?.length
    ? errors.additional_investors[index]
      ? (errors.additional_investors[index] as any)
      : {}
    : {};

  return (
    <div className="b relative mt-5 grid grid-cols-1 gap-y-4 border-t-[1px] border-gray-300 pt-10 md:col-span-2 md:grid-cols-2 lg:gap-y-8">
      <XMarkIcon
        className="absolute right-0 mt-3 h-6 w-6 cursor-pointer text-gray-500"
        onClick={() => {
          if (currentSize === 1) {
            setIsBDPart(false);
            setFieldValue("is_vpc", false);
          }
          remove(index);
          if (investor.id) {
            setDeletedInvestorIds([...deletedInvestorIds, investor.id]);
          }
        }}
      />
      <div className="flex w-full items-start text-xs">
        <Field
          as={FormVMFSelectedField}
          name={`additional_investors.${index}.fund_id`}
          placeholder={"Fund Name..."}
          label="Fund Name"
          errors={errorInvestorPart.fund_id}
          touched={true}
          required
          isMulti={false}
          fundType="child"
        />
      </div>
      <div className="flex w-full items-start">
        <div className="flex w-full flex-col md:w-11/12">
          <div className="mb-1 flex flex-row items-center">
            <label className="text-xs font-semibold" htmlFor="dateField">
              Holding Status
            </label>
            <div className="h-5 text-sm font-semibold text-red-600">*</div>
            <div>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`ml-2 flex h-5 w-5 items-center justify-center focus:outline-none`}
                    >
                      <InformationCircleIcon className="h-5 w-5 text-blue-900" />
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        static
                        className="absolute -left-24 z-top rounded border border-gray-200 bg-white p-2 drop-shadow-2xl sm:w-[450px] md:-left-[15rem] lg:-left-[8rem] xl:-left-[7rem]"
                      >
                        <div className=""></div>
                        <div className=""></div>
                        <div className="E"></div>
                        <table className="w-full text-xs">
                          <thead>
                            <tr className="bg-slate-200">
                              <th className="p-1 text-left">LP Report</th>
                              <th className="p-1 text-left">
                                Holding Status on Labs
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {HoldingStatusTooltip.map(
                              (info: any, index: number) => (
                                <tr
                                  className={`${
                                    index % 2 !== 0 ? "bg-gray-100" : ""
                                  }`}
                                  key={info.report}
                                >
                                  <td className="p-1">{info.report}</td>
                                  <td className="p-1">{info.stage}</td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div
            className={`border-1 relative h-fit w-full items-center rounded-md border ${
              errorInvestorPart.holding_status
                ? "border-red-500"
                : "border-blue-900"
            }`}
          >
            <Field name={`additional_investors.${index}.holding_status`}>
              {({ field, form: { setFieldValue } }: FieldProps) => (
                <div className="mr-7 w-full text-xs">
                  <Select
                    value={field.value}
                    options={holdingsOptions}
                    onChange={(newValue: any) => {
                      setFieldValue(field.name, newValue);
                    }}
                  />
                </div>
              )}
            </Field>
          </div>
          <div
            className={classNames(
              errorInvestorPart.holding_status
                ? "text-2xs text-red-600"
                : "hidden",
            )}
          >
            {errorInvestorPart.holding_status as any}
          </div>
        </div>
      </div>
      <div className="flex w-full items-start">
        <div className="flex w-full flex-col md:w-11/12">
          <div className="mb-1 flex items-center pr-4">
            <label className="text-xs font-semibold">
              First Financing Stage
            </label>
            <div className="h-5 text-sm font-semibold text-red-600">*</div>
            <div>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`ml-2 flex items-center justify-center focus:outline-none`}
                    >
                      <InformationCircleIcon className="h-5 w-5 text-blue-900" />
                    </Popover.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel
                        static
                        className="absolute -left-32 z-top rounded border border-gray-200 bg-white p-2 drop-shadow-2xl sm:w-[450px]"
                      >
                        <div className="font-bold">
                          Earliest Financing Stage
                        </div>
                        <div>
                          Only first financing round needed. Do not need latest
                          round.
                        </div>
                        <div className="mt-2">Example:</div>
                        <table className="w-full text-xs">
                          <thead>
                            <tr className="bg-slate-200">
                              <th className="p-1 text-left">LP Report</th>
                              <th className="p-1 text-left">
                                Financing Stage on Labs
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {FinancingStagesTooltip.map(
                              (info: any, index: number) => (
                                <tr
                                  className={`${
                                    index % 2 !== 0 ? "bg-gray-100" : ""
                                  }`}
                                  key={info.report}
                                >
                                  <td className="p-1">{info.report}</td>
                                  <td className="p-1">{info.stage}</td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
          <div
            className={`border-1 text-md relative block h-fit rounded-md border text-xs ${
              errorInvestorPart.financing_stage
                ? "border-red-500"
                : "border-blue-900"
            }`}
          >
            <Field
              name={`additional_investors.${index}.financing_stage`}
              label="Financing Stages"
              errors={undefined}
              touched={undefined}
              required
            >
              {({
                field: { name, value },
                form: { setFieldValue },
              }: FieldProps) => (
                <AutocompleteMultiselect
                  id="financingStage"
                  placeholder={"Financing Stages"}
                  autocompleteEndpoint="/api/people_map/autocomplete/financing_stages"
                  selected={value}
                  onChange={(newValue) => setFieldValue(name, newValue)}
                  isMulti={false}
                />
              )}
            </Field>
          </div>
          <div
            className={classNames(
              errorInvestorPart.financing_stage
                ? "text-2xs text-red-600"
                : "hidden",
            )}
          >
            {errorInvestorPart.financing_stage as any}
          </div>
        </div>
      </div>
      <div className="flex w-full items-start">
        <div className="flex w-full flex-col md:w-11/12">
          <div className="mb-1 flex">
            <label className="text-xs font-semibold" htmlFor="dateField">
              First Investment Date
            </label>
            <div className="h-5 text-sm font-semibold text-red-600">*</div>
          </div>
          <div
            className={`border-1 relative h-fit w-full items-center rounded-md border ${
              errorInvestorPart.investment_date
                ? "border-red-500"
                : "border-blue-900"
            }`}
          >
            <Field
              name={`additional_investors.${index}.investment_date`}
              className="w-full"
            >
              {({ field }: FieldProps) => (
                <>
                  <input
                    id="dateField"
                    type="date"
                    className="w-full rounded-md border-gray-300 p-2.5 text-xs"
                    {...field}
                  />
                </>
              )}
            </Field>
          </div>
          <div
            className={classNames(
              errorInvestorPart.investment_date
                ? "text-2xs text-red-600"
                : "hidden",
            )}
          >
            {errorInvestorPart.investment_date as any}
          </div>
        </div>
      </div>
      <div className="flex w-full items-start">
        <div className="grid w-full md:w-11/12">
          <div className="flex pr-4">
            <label className="text-xs font-semibold">Investment Partners</label>
          </div>
          <div className="text-xs">
            <Field name={`additional_investors.${index}.investment_partners`}>
              {({
                field: { name, value },
                form: { setFieldValue },
              }: FieldProps) => {
                return (
                  <PersonSelect
                    id="investment_partners"
                    value={value}
                    onChange={(value) => setFieldValue(name, value)}
                    isMulti
                    setValue={(value) => setFieldValue(name, value)}
                    showAddButton={false}
                    fullWidth={true}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrganizationVPCForm;
