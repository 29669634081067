import { Tab } from "@headlessui/react";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Companies } from "./Dashboard/Companies";
import { DashboardHeader, dashboardTabs } from "./Dashboard/DashboardUltils";
import { DashboardUpdates } from "./Dashboard/DashboardUpdates";
import { NetWorkCollaboration } from "./Dashboard/NetWorkCollaboration";
import LatestNews from "./LatestNews";

const Dashboard: React.FC = () => {
  const isDesktop = useMediaQuery({
    minWidth: 640,
  });
  const router = useRouter();
  const { tabId } = router.query;
  const [currentTab, setCurrentTab] = useState(dashboardTabs[0]);

  useEffect(() => {
    let selectedTab = dashboardTabs[0];
    if (tabId) {
      selectedTab =
        dashboardTabs.find((tab) => tab.id === tabId) || selectedTab;
    }
    setCurrentTab(selectedTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId, dashboardTabs]);

  return (
    <div>
      <div className="flex-col lg:overflow-hidden">
        {isDesktop ? (
          <div className="flex h-[calc(100vh-7rem)] flex-col gap-3 lg:flex-row">
            <div className="flex flex-col gap-y-3 lg:w-2/3">
              <div className="flex h-full w-full flex-col gap-y-3 sm:flex-row sm:gap-x-3">
                <Companies />
                <DashboardUpdates />
              </div>
            </div>
            <div className="flex min-w-[270px] flex-col gap-y-3 sm:flex-row sm:gap-y-0 sm:gap-x-3 lg:w-1/3 lg:flex-col lg:gap-x-0">
              <LatestNews />
              <NetWorkCollaboration />
            </div>
          </div>
        ) : (
          <Tab.Group>
            <Tab.List>
              <div className="z-100 flex h-10 w-full overflow-x-auto overflow-y-hidden whitespace-nowrap border-b border-t border-neutral-300 bg-white md:hidden">
                {dashboardTabs.map((tab: DashboardHeader) => (
                  <Tab key={tab.id} className={"focus:outline-none"}>
                    <div
                      className={`text-lg flex h-10 w-max items-center justify-center px-4 font-normal leading-7 text-gray-900 ${
                        currentTab.id === tab.id
                          ? "border-b-4 border-b-blue-600"
                          : "border-b border-neutral-300"
                      }`}
                      onClick={() => {
                        router.push({ search: `?tabId=${tab.id}` });
                      }}
                    >
                      {tab.headerName}
                    </div>
                  </Tab>
                ))}
              </div>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <Companies />
              </Tab.Panel>
              <Tab.Panel>
                <DashboardUpdates />
              </Tab.Panel>
              <Tab.Panel>
                <LatestNews />
              </Tab.Panel>
              <Tab.Panel>
                <NetWorkCollaboration />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
