import { useField } from "formik";
import { useEffect, useRef } from "react";
import CountrySelect from "../Select/CountrySelect";
import { classNames } from "../utils";

type FormCountriesSelectFieldProps = {
  id: string;
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
  isClearable: boolean;
  newValue?: any;
  bool?: boolean;
  onBlur?: () => void;
  placeholder?: string;
  customClass?: string;
};
const FormCountriesSelectField = ({
  id,
  name,
  required,
  label,
  errors,
  touched,
  isMulti = false,
  isClearable,
  newValue,
  bool,
  onBlur,
  placeholder,
  customClass,
}: FormCountriesSelectFieldProps) => {
  const [input, , helpers] = useField(name);

  const { value } = input;
  const { setValue } = helpers;
  const isSet = useRef(false);

  const handleCountryChange = (newValue: any) => {
    isSet.current = true;
    setValue(newValue);
  };

  useEffect(() => {
    if (isSet.current === false && bool === true) {
      setValue(newValue);
    }
    // eslint-disable-next-line
  }, [newValue]);

  return (
    <div className={`grid w-full md:w-11/12 ${customClass}`}>
      <div className="flex">
        <label className="text-xs font-semibold" htmlFor={name}>
          {label}
        </label>
        {required ? (
          <div className="text-sm font-semibold text-red-600">*</div>
        ) : null}
      </div>
      <div
        className={classNames(
          "relative h-fit w-full items-center rounded-md",
          touched
            ? errors
              ? "border-1 border border-red-500"
              : "border-1 border border-blue-900"
            : "border-1 border border-gray-300",
        )}
      >
        <CountrySelect
          id={id}
          value={value}
          onChange={handleCountryChange}
          isMulti={isMulti}
          isClearable={isClearable}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </div>

      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden",
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormCountriesSelectField;
