import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR, { useSWRConfig } from "swr";
import {
  calculateVisibility,
  createMeetingNote,
  getAudioUrlMeetingNotes,
  getter,
  meetingNotesTranscribe,
  updateMeetingNote,
} from "../../api";
import {
  AutocompleteResult,
  GroupAutocompleteResult,
  MeetingNote,
  NoteKind,
  OpportunityAutocompleteResult,
  OpportunityDetail,
  Option,
  OrganizationAutocompleteResult,
  PydanticError,
  Success,
  UserAutocompleteResult,
  Visibility,
} from "../../api/types";
import { useCurrentUser } from "../../components/AuthProvider";
import ErrorModal from "../../components/ErrorModal";
import MeetingNoteForm from "../../components/MeetingNoteForm";
import Spinner from "../../components/Spinner";
import { parseAxiosError } from "../../components/utils";
import { isValidJson } from "../../components/Utils/commons";
import { useScrollToFirstError } from "../../components/Utils/formUtils";
import { ShowSnackBar } from "../../components/Utils/supportMessage";

const MEETING_NOTES_SS_KEY = "meetingNoteFormValues";

interface MeetingNoteValues {
  noteKind: NoteKind;
  isMeetingNote: boolean;
  isDraft: boolean;
  title: string;
  types: string[];
  date: string;
  owner: UserAutocompleteResult | null;
  attendees: Option[];
  organizations: Option[];
  opportunities: OpportunityAutocompleteResult[];
  content: string;
  tags: Option[];
  visibility: Visibility;
  groups: GroupAutocompleteResult[];
  users: UserAutocompleteResult[];
  attachments: File[] | undefined;
  added_attachments: AutocompleteResult[];
  audioAttachments: File[] | undefined;
  added_audioAttachment: AutocompleteResult[];
  index_note: boolean;
  hasBigFileSize: boolean;
}

function meetingNoteToFormValues(meetingNote: MeetingNote): MeetingNoteValues {
  return {
    isMeetingNote: meetingNote.kind === "meeting",
    isDraft: false,
    attendees: meetingNote.attendees,
    content: meetingNote.content_html,
    organizations: meetingNote.organizations,
    opportunities: meetingNote.opportunities,
    owner: {
      label: meetingNote.owner.name,
      value: meetingNote.owner.id,
      email: meetingNote.owner.email,
    },
    date: meetingNote.date,
    title: meetingNote.title,
    types: meetingNote.types,
    tags: meetingNote.tags,
    visibility: calculateVisibility(meetingNote.access_policy),
    groups: meetingNote.access_policy
      ? meetingNote.access_policy.groups.map((group) => ({
          label: group.name,
          value: group.id,
        }))
      : [],
    users: meetingNote.access_policy
      ? meetingNote.access_policy.users.map((user) => ({
          label: user.name,
          value: user.id,
          email: user.email,
        }))
      : [],
    attachments: undefined,
    added_attachments: meetingNote.attachments,
    audioAttachments: undefined,
    added_audioAttachment: meetingNote.attachments,
    noteKind: meetingNote.kind,
    index_note:
      meetingNote.processed_at !== null || meetingNote.processed_start !== null,
    hasBigFileSize: false,
  };
}

type MeetingNoteFormProps = {
  valMessage?: string;
  organization?: OrganizationAutocompleteResult | null;
  opportunity?: OpportunityAutocompleteResult | null;
  onSuccess?: () => void;
  setMeetingNote?: React.Dispatch<
    React.SetStateAction<MeetingNote | undefined>
  >;
  isCreateNote?: boolean;
};

export async function getServerSideProps(context: any) {
  let organization = null;
  let opportunity = null;
  if (context.query.organization) {
    try {
      organization = JSON.parse(
        Buffer.from(context.query.organization, "base64").toString("ascii"),
      );
    } catch (error) {}
  }
  if (context.query.opportunity) {
    try {
      const opportunityDetail = JSON.parse(
        Buffer.from(context.query.opportunity, "base64").toString("ascii"),
      ) as OpportunityDetail;

      const oppoturnity_organizations =
        opportunityDetail.opportunity_organizations.map((org) => {
          return {
            label: org.organization_name,
            value: org.organization,
            domain: org.organization_domain,
            image_url: org.organization_logo_url,
          };
        });
      opportunity = {
        value: opportunityDetail.id,
        label: opportunityDetail.name,
        opportunity_type: opportunityDetail.opportunity_type.name,
        funnel_stage: opportunityDetail.funnel_stage.name,
        types: ["Investment"],
        organizations: oppoturnity_organizations,
        owner: opportunityDetail.owner,
      } as OpportunityAutocompleteResult;
    } catch (error) {}
  }
  return {
    props: {
      organization: organization,
      opportunity: opportunity,
    },
  };
}

const CreateEditMeetingNote = ({
  organization,
  opportunity,
  valMessage,
  onSuccess,
  setMeetingNote,
  isCreateNote = false,
}: MeetingNoteFormProps) => {
  const { cache } = useSWRConfig();
  const router = useRouter();
  const { pk } = router.query;
  const { user: currentUser } = useCurrentUser();
  const [isClearForm, setIsClearForm] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [submit, setSubmit] = useState<boolean>(false);
  const [formErrors, setFormErrors] =
    useState<FormikErrors<MeetingNoteValues>>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  useScrollToFirstError(formErrors, submit, setSubmit);

  const { data, error, mutate } = useSWR<Success<MeetingNote>>(
    pk && !isCreateNote ? `/api/people_map/meeting_notes/${pk}` : null,
    getter,
    { revalidateOnMount: true },
  );

  const meetingNote: MeetingNote | undefined = data?.data;

  const initialOwner = {
    label: currentUser.name,
    value: currentUser.id,
    email: currentUser.email,
    image_url: currentUser.image_url,
  };
  const defaultDate: string = new Date().toISOString().slice(0, 10);

  const emptyValues: MeetingNoteValues = {
    isMeetingNote: true,
    isDraft: false,
    attendees: [],
    content: "",
    organizations: opportunity
      ? opportunity.organizations
      : organization
        ? [organization]
        : [],
    opportunities: opportunity ? [opportunity] : [],
    owner: initialOwner,
    date: defaultDate,
    title: "",
    types: opportunity?.types || [],
    tags: [],
    visibility: "groups",
    groups: [],
    users: [],
    attachments: undefined,
    added_attachments: [],
    audioAttachments: undefined,
    added_audioAttachment: [],
    noteKind: "meeting",
    index_note: false,
    hasBigFileSize: false,
  };

  let storageValues = localStorage.getItem(MEETING_NOTES_SS_KEY);
  let initialValues = meetingNote
    ? meetingNoteToFormValues(meetingNote)
    : !pk && storageValues
      ? {
          ...JSON.parse(storageValues),
          date: defaultDate,
          organizations: emptyValues.organizations,
          opportunities: emptyValues.opportunities,
        }
      : emptyValues;

  if (valMessage && valMessage.length > 0) {
    initialValues = { ...initialValues, content: valMessage };
  }

  const validate = (values: MeetingNoteValues) => {
    const errors: FormikErrors<MeetingNoteValues> = {};
    if (!values.title) {
      errors.title = "Title is required";
    }
    if (values.types.length === 0) {
      errors.types = "At least one note type is required";
    }
    if (!values.owner) {
      errors.owner = "Owner is required";
    }
    if (!values.date) {
      errors.date = "Date is required";
    }
    if (
      values.visibility === "groups" &&
      !values.groups?.length &&
      !values.users?.length
    ) {
      errors.groups = "at least one group OR user should be selected";
    }
    if (!pk && !isClearForm) {
      try {
        localStorage.setItem(
          MEETING_NOTES_SS_KEY,
          JSON.stringify({ ...values, attachments: [], audioAttachments: [] }),
        );
      } catch (ex) {
        errors.content =
          "The total file size is too large. Please remove some items and try submitting again.";
      }
    }
    if (values.hasBigFileSize) {
      errors.content =
        "The total file size is too large. Please remove some items and try submitting again.";
    }
    setIsClearForm(false);
    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = (
    values: MeetingNoteValues,
    { setErrors }: FormikHelpers<MeetingNoteValues>,
  ) => {
    setIsProcessing(true);
    const payLoad = {
      is_meeting_note: values.isMeetingNote ?? true,
      is_draft: values.isDraft,
      title: values.title,
      types: values.types,
      owner: values.owner?.value as number,
      date: values.date,
      attendees: (values.attendees || []).map(
        (attendee) => attendee.value as number,
      ),
      organizations: (values.organizations || []).map(
        (organization) => organization.value as number,
      ),
      opportunities: (values.opportunities || []).map(
        (opportunity) => opportunity.value as number,
      ),
      tags: (values.tags ?? []).map((tag) => tag.label),
      content: values.content ?? "",
      visibility: values.visibility,
      noteKind: values.noteKind,
      groups: (values.groups || []).map((option) => option.value),
      users: (values.users || []).map((option) => option.value),
      attachment_ids: (values.added_attachments || []).map(
        (attachment) => attachment.value,
      ),
      index_note: values.index_note,
    };
    const submitFunction =
      meetingNote && !valMessage
        ? updateMeetingNote(payLoad, values.attachments, parseInt(pk as string))
        : createMeetingNote(payLoad, values.attachments);

    submitFunction
      .then((response) => {
        const storageData = localStorage.getItem(MEETING_NOTES_SS_KEY);
        if (storageData && isValidJson(storageData)) {
          const data = JSON.parse(storageData);
          localStorage.setItem(
            MEETING_NOTES_SS_KEY,
            JSON.stringify({
              title: "",
              owner: initialOwner,
              date: data.date,
              visibility: data.visibility,
              users: data.users,
              groups: data.groups ?? [],
              types: data.types ?? [],
              organizations: [],
              opportunities: [],
              attendees: [],
              added_attachments: [],
              tags: [],
            }),
          );
        }
        if (response.data.data.id) {
          mutate();
          if (values.audioAttachments && values.audioAttachments.length > 0) {
            ShowSnackBar(
              "Audio transcription clip is added to meeting note. The transcription may take some time to process and an email will be sent to you once it is ready.",
              true,
            );
          }
          if (valMessage && onSuccess) {
            setMeetingNote && setMeetingNote(response.data.data);
            onSuccess();
          } else {
            cache.set(`/api/people_map/meeting_notes/${pk}`, null);
            if (values.audioAttachments && values.audioAttachments.length > 0) {
              router.push(`/meeting_notes/${response.data.data.id}`);
            } else {
              router.push(`/meeting_notes/${response.data.data.id}`);
            }
          }
        }

        if (values.audioAttachments && values.audioAttachments.length > 0) {
          const file = values.audioAttachments[0];
          const params: Record<string, any> = {
            blob_name: file.name.replace(/[|&;$%@"<>()+,]/g, ""),
            cache_buster: new Date().getTime(),
          };

          getAudioUrlMeetingNotes(new URLSearchParams(params))
            .then(async (url_response) => {
              if (url_response) {
                const url = url_response.data.data.signed_url;
                const fileName = url_response.data.data.file_name;
                const uploadFileResponse = await fetch(url, {
                  method: "PUT",
                  body: file,
                  headers: {
                    "Content-Type": "application/octet-stream",
                  },
                });
                if (uploadFileResponse.ok) {
                  const transcribeParams: Record<string, any> = {
                    signed_audio_file_name: fileName,
                  };
                  meetingNotesTranscribe(
                    response.data.data.id,
                    new URLSearchParams(transcribeParams),
                  ).catch((error) => {
                    setShowErrorModal(true);
                    setErrorMessage(parseAxiosError(error));
                  });
                }
              }
            })
            .catch((error) => {
              setShowErrorModal(true);
              setErrorMessage(parseAxiosError(error));
            });
        }
      })
      .catch((error) => {
        switch (error.response.status) {
          case 422:
            const errors: PydanticError = error.response.data;
            // formik errors from Pydantic body errors
            setErrors(
              Object.fromEntries(errors.detail.map((v) => [v.loc[2], v.msg])),
            );
            return;
          case 400:
            // formik errors from api business logic errors
            setErrors(error.response.data.data);
            return;
          case 500:
          case 502:
            setShowErrorModal(true);
            setErrorMessage(error.message);
            return;
          case 413:
            setShowErrorModal(true);
            setErrorMessage(
              "Error: File size is more than the permitted limit of 32MB.",
            );
            return;
        }
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setIsProcessing(false);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  useEffect(() => {
    if (isClearForm && localStorage.getItem(MEETING_NOTES_SS_KEY)) {
      localStorage.removeItem(MEETING_NOTES_SS_KEY);
    }
    // eslint-disable-next-line
  }, [isClearForm]);

  return (
    <div className="p-3 sm:p-0">
      <div className="flex items-center space-x-4">
        <div className="h-8 w-2 rounded-full bg-blue-900" />
        <h2 className="mt-3 mb-3 text-base font-bold tracking-wide md:text-2xl">
          {meetingNote ? "Edit Note" : "Create Note"}
        </h2>
      </div>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
        initialStatus={null}
        key={pk?.toString()}
      >
        {({
          values,
          setFieldValue,
          resetForm,
        }: FormikProps<MeetingNoteValues>) => (
          <Form className="grid gap-y-3">
            <MeetingNoteForm values={values} />
            <div className="mt-4 mb-20 flex justify-end gap-x-4">
              {!pk && (
                <span
                  className="flex-column flex cursor-pointer items-center text-xs text-blue-900"
                  onClick={() => {
                    setIsClearForm(true);
                    resetForm({ values: emptyValues });
                    if (localStorage.getItem(MEETING_NOTES_SS_KEY)) {
                      localStorage.removeItem(MEETING_NOTES_SS_KEY);
                    }
                  }}
                >
                  Clear Form
                </span>
              )}
              <button
                className="btn-primary"
                onClick={async () => {
                  setSubmit(true);
                  try {
                    setFieldValue("isDraft", false);
                  } catch (error: any) {
                    setShowErrorModal(true);
                    if (error.response && error.response.status === 429) {
                      setErrorMessage(
                        "Quota exceeded error. Please remove any large image size and try again.",
                      );
                    } else if (
                      error.response &&
                      error.response.status === 403
                    ) {
                      setErrorMessage(parseAxiosError(error));
                    } else {
                      setErrorMessage(
                        "An unexpected error occurred. Please try again.",
                      );
                    }
                  }
                }}
              >
                {isProcessing ? (
                  <div className="flex flex-row items-center gap-x-2 text-white">
                    <div>
                      <Spinner className="h-4 w-4 text-white" />
                    </div>
                    <div>Submitting</div>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default CreateEditMeetingNote;
