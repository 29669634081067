import { PlusIcon } from "@heroicons/react/24/solid";
import { FormikErrors, FormikTouched, useField } from "formik";
import React, { useState } from "react";
import { OrganizationAutocompleteResult } from "../../api/types";
import OrganizationForm from "../discover/Organizations/OrganizationForm";
import NewModal from "../NewModal";
import OrganizationSelect from "../Select/OrganizationSelect";
import Tooltip from "../Tooltip";
import { classNames } from "../utils";
import { DescriptionTooltipText } from "../VPC/VertexPortfolioUtils";

type FormCompanySelectFieldProps = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  required: boolean;
  errors:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  flexible: boolean;
  fixWidth?: boolean;
  onChange?: (value: any) => void;
  countryChange?: (value: any) => void;
  descriptionChange?: (value: any) => void;
  directCreate?: boolean;
  setIsDescriptionEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNameEdited?: React.Dispatch<React.SetStateAction<boolean>>;
  withNinja?: boolean;
  showAddOrganizationButton?: boolean;
  customClass?: string;
  isMulti?: boolean;
  onBlur?: () => void;
  setId?: React.Dispatch<
    React.SetStateAction<string | number | null | undefined>
  >;
  vpc?: boolean;
};

const FormCompanySelectField = ({
  id,
  name,
  required,
  label,
  placeholder,
  errors,
  touched,
  flexible,
  fixWidth,
  onChange,
  countryChange,
  descriptionChange,
  directCreate = false,
  setIsDescriptionEdited,
  setIsNameEdited,
  withNinja,
  showAddOrganizationButton = false,
  customClass,
  isMulti,
  onBlur,
  setId,
  vpc = false,
}: FormCompanySelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  let { value, checked } = input;
  const { setValue } = helpers;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div className="flex">
        <label className="text-xs font-semibold" htmlFor={name}>
          {label}
        </label>
        {required ? (
          <div className="text-sm font-semibold text-red-600">*</div>
        ) : (
          ""
        )}
        {vpc && (
          <Tooltip
            tooltipClass="!max-w-full !w-[500px]"
            content={<DescriptionTooltipText />}
          />
        )}
      </div>
      <div className="flex gap-2">
        <div
          className={`w-full rounded-md ${customClass} ${
            touched
              ? errors
                ? "border-1 border border-red-500"
                : "border-1 border border-blue-900"
              : "border-gray-300"
          }`}
        >
          <OrganizationSelect
            id={id}
            withNinja={withNinja}
            value={value?.value || value?.length ? value : []}
            fixWidth={fixWidth}
            placeholder={placeholder}
            isMulti={isMulti}
            onChange={(newValue: any) => {
              setValue(newValue);
              if (countryChange) {
                countryChange(newValue?.country);
              }
              if (descriptionChange) {
                descriptionChange(newValue?.description);
              }
              if (onChange?.length) {
                onChange(newValue);
              }
              if (setId && newValue?.value) {
                setId(newValue?.value);
              }
            }}
            flexible={flexible}
            directCreate={directCreate}
            onBlur={onBlur}
          />
        </div>
        {showAddOrganizationButton && (
          <div
            className="mt-2 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-blue-900"
            onClick={() => setIsOpen(true)}
          >
            <PlusIcon className="h-5 w-5 fill-white" />
          </div>
        )}
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden",
        )}
      >
        {errors?.toString()}
      </div>
      <NewModal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <OrganizationForm
          isOpen={isOpen}
          title="Add New Organization"
          setIsOpen={setIsOpen}
          newOrganization={(organization) => {
            const newOrganization: OrganizationAutocompleteResult = {
              label: organization.name,
              value: organization.id,
              domain: organization.domain,
              image_url: organization.logo_url,
              description: organization.description,
            };

            if (isMulti) {
              setValue([...(value || []), newOrganization]);
            } else {
              setValue(newOrganization);
            }

            setIsDescriptionEdited && setIsDescriptionEdited(false);
            setIsNameEdited && setIsNameEdited(false);
          }}
          isModal={true}
          icon="PlusCircleIcon"
        />
      </NewModal>
    </div>
  );
};

export default FormCompanySelectField;
